<template>
  <div>
    <el-row class="content-searcha">
      <el-form
        :inline="true"
        size="small"
        :rules="rules"
        ref="searchData"
        :model="search"
      >
        <el-form-item prop="warehouseId">
          <!-- <el-select v-model="search.warehouseId" placeholder="请选择仓库" clearable>
                       <el-option
                       v-for="item in sysConfig.warehouselist"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id"
                       >
                       </el-option>
                   </el-select> -->
          <WarehouseMultSelector
            v-model="search.warehouseId"
            placeholder="请选择仓库"
            clearable
          />
        </el-form-item>
        <el-form-item prop="_date">
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getstockOutInList()"
            >搜索</el-button
          >
          <!-- <el-button type="primary" @click="$refs['export-dialog'].show('F01')"
            >导出下载</el-button
          > -->
          <el-button type="primary" @click="handleExport"
            >导出下载</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table stripe :data="stockOutInlist" border width="100%">
        <el-table-column label="采购单号">
          <template slot-scope="{ row }">
            <p>{{ row.order_no }}</p>
          </template>
        </el-table-column>
        <el-table-column label="仓库单号">
          <template slot-scope="{ row }">
            <p>{{ row.order_id }}</p>
            <el-tag>{{ row.aino }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="ship_date"
          label="采购交期"
        ></el-table-column>
        <el-table-column prop="updated_at" label="日期"></el-table-column>
        <el-table-column prop="warehouse" label="仓库"></el-table-column>
        <!-- <el-table-column prop="user_name" label="仓管"></el-table-column> -->
        <el-table-column prop="vendor" label="供应商"></el-table-column>
        <el-table-column
          prop="user"
          label="采购员"
        ></el-table-column>
        <el-table-column prop="mfn" label="MFN"></el-table-column>
        <el-table-column
          prop="product"
          min-width="150%"
          label="品名"
        ></el-table-column>
        <el-table-column prop="dimension" label="规格"></el-table-column>
        <el-table-column prop="qty" label="总数"></el-table-column>
        <el-table-column
          width="120"
          label="采购价（税）"
        >
          <template slot-scope="{ row }">
            <span>{{ row.currency }} {{ row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          label="采购金额（税）"
        ></el-table-column>
        <el-table-column
          prop="price_no_tax"
          label="采购价"
        ></el-table-column>
        <el-table-column
          prop="amount_no_tax"
          label="采购金额"
        ></el-table-column>
        <!-- <el-table-column prop="remark" label="备注"></el-table-column> -->
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageData.current_page"
        :page-size="parseInt(pageData.per_page)"
        :page-sizes="[100, 300, 500]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageData.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      >
      </el-pagination>
    </el-row>
    <!-- <export-dialog ref="export-dialog"></export-dialog> -->
    <xlsx-downloader ref="xlsx-downloader" type="FINANCE_PROCUREMENT_IN_OUT" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { baseUrl } from '@/config/env'
import * as warehouseBillApi from '@/api/warehouseBill'
import WarehouseMultSelector from '@/components/finance/warehouseBills/WarehouseMultSelector'
import XlsxDownloader from '@/components/xlsx-downloader'

export default {
  components: {
    WarehouseMultSelector,
    XlsxDownloader
  },
  props: ['type'],
  data () {
    return {
      search: {
        warehouseId: [],
        _date: ''
      },
      stockOutInlist: [],
      pageData: {
        current_page: 1,
        per_page: 100
      },
      rules: {
        warehouseId: [
          { required: true, message: '请选择仓库', trigger: 'change' }
        ],
        _date: [{ required: true, message: '请选择日期', trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  watch: {
    type: function () {
      this.getstockOutInList()
    }
  },
  created () {
    // this.getstockOutInList()
  },
  methods: {
    handleExport () {
      // TODO: type暂时不传
      const params = {
        date_start: this.search.start,
        date_end: this.search.end,
        warehouse_id: this.search.warehouseId.join(',')
      }
      this.$refs['xlsx-downloader'].show(params)
    },
    // 设置日期
    setDate () {
      if (this.search._date == null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getstockOutInList () {
      const self = this
      console.log(self.type)
      const listJsonData = {
        type: self.type,
        warehouse_id: self.search.warehouseId,
        date_start: self.search.start,
        date_end: self.search.end,
        page: self.pageData.current_page,
        per_page: self.pageData.per_page
      }
      warehouseBillApi
        .getStockOutInDetail(listJsonData)
        .then(data => {
          self.stockOutInlist = data.data
          self.pageData.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageData.current_page = currentPage
      this.getstockOutInList()
    },
    changeSizeHandler (perPage) {
      this.pageData.per_page = perPage
      this.getstockOutInList()
    },
    downloadLink () {
      const url =
        baseUrl +
        'finance/wms/exportPurchaseInOut?token=' +
        localStorage.getItem('token') +
        '&type=' +
        this.type +
        '&warehouse_id=' +
        this.search.warehouseId.join(',') +
        '&date_start=' +
        this.search.start +
        '&date_end=' +
        this.search.end
      window.open(url)
    }
  }
}
</script>
