<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">仓库单据</el-breadcrumb-item>
        <el-breadcrumb-item>采购出入库明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-row">
      <el-tabs v-model="tabvalue">
        <el-tab-pane
          v-for="item in tabs"
          :label="item.val"
          :key="item.key"
          :name="item.key.toString()"
        ></el-tab-pane>
      </el-tabs>
    </el-row>
    <el-row>
      <stock-out-in-list :type="tabvalue"></stock-out-in-list>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import stockOutInList from './stock-out-in-list'
export default {
  name: 'finance_warehouse-receipts_procurement-in-out',
  components: {
    stockOutInList
  },
  data () {
    return {
      // tabs: [],
      tabvalue: '1'
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    tabs () {
      return this.sysConfig.FinanceWMSPurchaseInOut.type
    }
  }
}
</script>
